.project-card {
    display: flex;
    flex-direction: column;
    margin: 1em;
}

.top-section {
    display: flex;
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    align-items: center;
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    flex-shrink: 0;
    margin-left: 1em;
}

.project-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1em;
    width: 100%;
}

.title-time {
    text-align: center;
}

.bottom-section {
    padding: 1em;
    text-align: left;
}

.description-text {
    text-align: left; /* Ensure description text is left-aligned */
}

.url {
    text-align: center;
}

@media (max-width: 576px) {
    .project-card {
        flex-direction: column;
    }

    .top-section {
        flex-direction: column;
        align-items: center;
    }

    .text-container {
        padding-left: 0;
        text-align: center;
    }

    .image-container {
        width: 100px;
        height: 100px;
        margin-bottom: 15px;
    }

    .project-image {
        width: 100px;
        height: 100px;
    }

    .project-body {
        text-align: center;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .project-card {
        flex-direction: row;
        align-items: center; /* Vertically center the image */
    }

    .top-section {
        flex-direction: row;
        align-items: center;
    }

    .image-container {
        width: 200px;
        height: 200px;
    }

    .project-image {
        width: 200px;
        height: 200px;
    }

    .text-container {
        padding-left: 15px;
        width: 100%;
    }

    .title-time {
        text-align: left;
        padding-bottom: 10px;
    }

    .bottom-section {
        text-align: left;
        padding-left: 0;
    }

    .description-text {
        text-align: left;
    }

    .url {
        text-align: center;
    }
}
