/* Ensure all nav links and brand are vertically centered */
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.nav-link {
    display: flex;
    align-items: center;
    padding: 0 15px; /* Adjust padding as needed */
}

.navbar-brand {
    display: flex;
    align-items: center;
    padding: 0 15px; /* Adjust padding as needed */
}

.form-control {
    margin: auto 0;
}

.btn-outline-light {
    margin: auto 0;
}
